"use client";

import { sendGAEvent } from "@next/third-parties/google";
import { EventEmitter } from "events";
import dynamic from "next/dynamic";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import { authenticate } from "../authenticate";
import LoadingModal from "../loading-modal";

const ResaleListingForm = dynamic(() => import("./form"), {
  ssr: false,
  loading: () => <LoadingModal />,
});

class ResaleListingEventEmitter extends EventEmitter {
  private static instance = new ResaleListingEventEmitter();

  constructor() {
    super();
  }

  static getInstance() {
    return this.instance;
  }
}

export const openResaleListingModal = async ({
  projectId,
  source,
}: {
  projectId?: string;
  source: string;
}) => {
  try {
    await authenticate({ source: "list_for_resale" });
    ResaleListingEventEmitter.getInstance().emit("resale-listing", {
      projectId,
      source,
    });
  } catch (error) {
    console.log("User cancelled");
  }
};

export default function ResaleListingModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [projectId, setProjectId] = useState<string>();

  const posthog = usePostHog();

  useEffect(() => {
    ResaleListingEventEmitter.getInstance().on(
      "resale-listing",
      ({ projectId, source }) => {
        setProjectId(projectId);
        setIsOpen(true);

        posthog.capture("list_for_resale_modal_opened", {
          source,
        });
        sendGAEvent("event", "list_for_resale_modal_opened", {
          source,
        });
      }
    );
  }, [posthog]);

  if (!isOpen) return null;

  return (
    <ResaleListingForm
      projectId={projectId}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
}
