import { cn } from "@/lib/utils";

interface SeparatorProps {
  className?: string;
}

export function SeparatorComponent({ className }: SeparatorProps = {}) {
  return (
    <div
      className={cn("h-px w-full bg-gray-200 my-2", className)}
      role="separator"
    />
  );
}
